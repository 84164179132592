import { getCollections, getDate, cambiarTextos } from './functions';
import groupBy from 'lodash.groupby';
let xlsx = require('json-as-xlsx');

export async function SatisfaccionGeneralReporte() {
	let array = [];
	const results = await getCollections('satisfaccionGeneral');
	Object.values(results).map((val) => {
		array.push({
			...val,
			preguntaSeis: parseInt(val.preguntaSeis),
			preguntaSiete: parseInt(val.preguntaSiete),
			preguntaOcho: parseInt(val.preguntaOcho),
			preguntaNueve: parseInt(val.preguntaNueve),
			preguntaDiez: parseInt(val.preguntaDiez),
			preguntaOnce: parseInt(val.preguntaOnce),
			preguntaDoce: parseInt(val.preguntaDoce),
			preguntaTrece: parseInt(val.preguntaTrece),
			preguntaCatorce: parseInt(val.preguntaCatorce),
			preguntaQuince: parseInt(val.preguntaQuince),
		});
	});
	const fecha = cambiarTextos(getDate());
	let data = [
		{
			sheet: 'Satisfacción General',
			columns: [
				{
					label: 'Por favor indique la empresa a la que pertenece',
					value: 'preguntaUno',
				},
				{ label: 'País de Operación del Cliente', value: 'preguntaDos' },
				{ label: 'País de Consultor Comercial', value: 'preguntaTres' },
				{
					label: 'Por favor seleccione al consultor comercial. Si su respuesta es Otro por favor indique el nombre.',
					value: 'preguntaCuatro',
				},
				{
					label: 'Por favor indique el proyecto o propuesta a evaluar',
					value: 'preguntaCinco',
				},
				{
					label: '¿El asesor comercial fue cordial durante todo el proceso comercial?',
					value: 'preguntaSeis',
				},
				{
					label: '¿El asesor comercial fue puntual en las citas acordadas?',
					value: 'preguntaSiete',
				},
				{
					label: '¿El asesor comercial realizó la presentación de Spira y le permitió entender con claridad los servicios que prestamos, nuestra experiencia y casos de éxito?',
					value: 'preguntaOcho',
				},
				{
					label: '¿El Asesor comercial demostró haber entendido las necesidades de su negocio /operación cuando presentó la propuesta?',
					value: 'preguntaNueve',
				},
				{
					label: '¿El asesor comercial le entregó la propuesta comercial en el tiempo acordado?',
					value: 'preguntaDiez',
				},
				{
					label: '¿Cuándo el asesor comercial presentó la oferta personalmente, le aclaró las dudas o inquietudes presentadas por su organización?',
					value: 'preguntaOnce',
				},
				{
					label: '¿El asesor comercial evidenció los beneficios que obtendrá su organización en el aumento del desempeño y/o el cumplimiento de sus objetivos?',
					value: 'preguntaDoce',
				},
				{
					label: '¿La propuesta comercial fue clara y respondió a sus necesidades?',
					value: 'preguntaTrece',
				},
				{
					label: '¿Después de la presentación de la propuesta, el asesor comercial mantuvo un permanente contacto e hizo seguimiento de esta?',
					value: 'preguntaCatorce',
				},
				{
					label: 'En una escala de 0 a 10, ¿En qué medida estaría dispuesto a recomendar SPIRA a sus conocidos?',
					value: 'preguntaQuince',
				},
				{ label: 'Fecha de registro', value: 'fechaRegistro' },
			],
			content: array,
		},
	];

	let settings = {
		fileName: `Satisfacción-General-${fecha}`, // Name of the resulting spreadsheet
		extraLength: 3, // A bigger number means that columns will be wider
		writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
	};

	xlsx(data, settings);
}

export async function SatisfaccionGeneralGReporte() {
	let array = [];
	const results = await getCollections('satisfaccionGeneralG');
	Object.values(results).map((val) => {
		array.push({
			...val,
			preguntaSeis: parseInt(val.preguntaSeis),
			preguntaSiete: parseInt(val.preguntaSiete),
			preguntaOcho: parseInt(val.preguntaOcho),
			preguntaNueve: parseInt(val.preguntaNueve),
			preguntaDiez: parseInt(val.preguntaDiez),
			preguntaOnce: parseInt(val.preguntaOnce),
			preguntaDoce: parseInt(val.preguntaDoce),
			preguntaTrece: parseInt(val.preguntaTrece),
			preguntaCatorce: parseInt(val.preguntaCatorce),
			preguntaQuince: parseInt(val.preguntaQuince),
		});
	});
	const fecha = cambiarTextos(getDate());
	let data = [
		{
			sheet: 'Satisfacción General Ganadas',
			columns: [
				{
					label: 'Por favor indique la empresa a la que pertenece',
					value: 'preguntaUno',
				},
				{ label: 'País de Operación del Cliente', value: 'preguntaDos' },
				{ label: 'País de Consultor Comercial', value: 'preguntaTres' },
				{
					label: 'Por favor seleccione al consultor comercial. Si su respuesta es Otro por favor indique el nombre.',
					value: 'preguntaCuatro',
				},
				{
					label: 'Por favor indique el proyecto o propuesta a evaluar',
					value: 'preguntaCinco',
				},
				{
					label: '¿El asesor comercial fue cordial durante todo el proceso comercial?',
					value: 'preguntaSeis',
				},
				{
					label: '¿El asesor comercial fue puntual en las citas acordadas?',
					value: 'preguntaSiete',
				},
				{
					label: '¿El asesor comercial realizó la presentación de Spira y le permitió entender con claridad los servicios que prestamos, nuestra experiencia y casos de éxito?',
					value: 'preguntaOcho',
				},
				{
					label: '¿El Asesor comercial demostró haber entendido las necesidades de su negocio /operación cuando presentó la propuesta?',
					value: 'preguntaNueve',
				},
				{
					label: '¿El asesor comercial le entregó la propuesta comercial en el tiempo acordado?',
					value: 'preguntaDiez',
				},
				{
					label: '¿Cuándo el asesor comercial presentó la oferta personalmente, le aclaró las dudas o inquietudes presentadas por su organización?',
					value: 'preguntaOnce',
				},
				{
					label: '¿El asesor comercial evidenció los beneficios que obtendrá su organización en el aumento del desempeño y/o el cumplimiento de sus objetivos?',
					value: 'preguntaDoce',
				},
				{
					label: '¿La propuesta comercial fue clara y respondió a sus necesidades?',
					value: 'preguntaTrece',
				},
				{
					label: '¿Después de la presentación de la propuesta, el asesor comercial mantuvo un permanente contacto e hizo seguimiento de esta?',
					value: 'preguntaCatorce',
				},
				{
					label: 'En una escala de 0 a 10, ¿En qué medida estaría dispuesto a recomendar SPIRA a sus conocidos?',
					value: 'preguntaQuince',
				},
				{ label: 'Fecha de registro', value: 'fechaRegistro' },
			],
			content: array,
		},
	];

	let settings = {
		fileName: `Satisfacción-General-Ganadas-${fecha}`, // Name of the resulting spreadsheet
		extraLength: 3, // A bigger number means that columns will be wider
		writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
	};

	xlsx(data, settings);
}

export async function SatisfaccionOdeEntranamiento() {
	let array = [];
	const results = await getCollections('satisfaccionODE');
	Object.values(results).map((val) => {
		array.push({
			...val,
			preguntaCinco: parseInt(val.preguntaCinco),
			preguntaSeis: parseInt(val.preguntaSeis),
			preguntaSiete: parseInt(val.preguntaSiete),
			preguntaOcho: parseInt(val.preguntaOcho),
			preguntaNueve: parseInt(val.preguntaNueve),
			preguntaDiez: parseInt(val.preguntaDiez),
			preguntaOnce: parseInt(val.preguntaOnce),
			preguntaDoce: parseInt(val.preguntaDoce),
			preguntaTrece: parseInt(val.preguntaTrece),
			preguntaCatorce: parseInt(val.preguntaCatorce),
		});
	});
	const fecha = cambiarTextos(getDate());
	let data = [
		{
			sheet: 'Satisfacción ODE Entrenamiento',
			columns: [
				{
					label: 'Por favor indique la empresa a la que pertenece',
					value: 'preguntaUno',
				},
				{ label: 'País de Operación del Cliente', value: 'preguntaDos' },
				{ label: 'País de Consultor Comercial', value: 'preguntaTres' },
				{
					label: 'Por favor indique el nombre del proyecto a evaluar',
					value: 'preguntaCuatro',
				},
				{
					label: '¿El equipo SPIRA generó empatía durante todo el proceso de implementación del proyecto?',
					value: 'preguntaCinco',
				},
				{
					label: '¿El equipo SPIRA definió un plan o cronograma de trabajo, donde especificó las fechas de entrega (parcial o total) del proyecto y criterios de aceptación de este por parte del cliente?',
					value: 'preguntaSeis',
				},
				{
					label: '¿El equipo SPIRA generó confianza por la claridad y conocimiento de las necesidades y expectativas del proyecto?',
					value: 'preguntaSiete',
				},
				{
					label: '¿El equipo SPIRA presentó una secuencia de actividades detallada e ilustrativa del proyecto a desarrollar?',
					value: 'preguntaOcho',
				},
				{
					label: '¿El equipo SPIRA contaba con los recursos humanos, técnicos y metodológicos necesarios para la ejecución del proyecto?',
					value: 'preguntaNueve',
				},
				{
					label: '¿El equipo SPIRA demostró tener los conocimientos y experiencia requeridos para la ejecución del proyecto?',
					value: 'preguntaDiez',
				},
				{
					label: '¿El equipo SPIRA realizó un seguimiento permanente y periódico del proyecto, de modo que se atendieron las inquietudes y requerimientos solicitados?',
					value: 'preguntaOnce',
				},
				{
					label: '¿El equipo SPIRA hizo las entregas pactadas con calidad y de acuerdo con las expectativas planteadas al inicio del proyecto?',
					value: 'preguntaDoce',
				},
				{
					label: '¿La solución presentada cumplió con sus expectativas en términos de calidad e innovación?',
					value: 'preguntaTrece',
				},
				{
					label: 'En una escala de 0 a 10, ¿En qué medida estaría dispuesto a recomendar SPIRA a sus conocidos?',
					value: 'preguntaCatorce',
				},
				{ label: 'Fecha de registro', value: 'fechaRegistro' },
			],
			content: array,
		},
	];

	let settings = {
		fileName: `Satisfacción-ODE-Entrenamientos-${fecha}`, // Name of the resulting spreadsheet
		extraLength: 3, // A bigger number means that columns will be wider
		writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
	};

	xlsx(data, settings);
}

export async function SatisfaccionOperaciones() {
	let array = [];
	const results = await getCollections('satisfaccionOperaciones');
	Object.values(results).map((val) => {
		array.push({
			...val,
			preguntaCinco: parseInt(val.preguntaCinco),
			preguntaSeis: parseInt(val.preguntaSeis),
			preguntaSiete: parseInt(val.preguntaSiete),
			preguntaOcho: parseInt(val.preguntaOcho),
			preguntaNueve: parseInt(val.preguntaNueve),
			preguntaDiez: parseInt(val.preguntaDiez),
			preguntaOnce: parseInt(val.preguntaOnce),
			preguntaDoce: parseInt(val.preguntaDoce),
			preguntaTrece: parseInt(val.preguntaTrece),
			preguntaCatorce: parseInt(val.preguntaCatorce),
			preguntaQuince: parseInt(val.preguntaQuince),
			preguntaDieciseis: parseInt(val.preguntaDieciseis),
			preguntaDiecisiete: parseInt(val.preguntaDiecisiete),
			preguntaDieciocho: parseInt(val.preguntaDieciocho),
			preguntaDiecinueve: parseInt(val.preguntaDiecinueve),
			preguntaVeinte: parseInt(val.preguntaVeinte),
		});
	});
	const fecha = cambiarTextos(getDate());
	let data = [
		{
			sheet: 'Satisfacción Operaciones',
			columns: [
				{
					label: 'Por favor indique la empresa a la que pertenece',
					value: 'preguntaUno',
				},
				{ label: 'País de Operación del Cliente', value: 'preguntaDos' },
				{ label: 'País de Consultor Comercial', value: 'preguntaTres' },
				{
					label: 'Por favor indique el nombre del proyecto a evaluar',
					value: 'preguntaCuatro',
				},
				{
					label: '¿El equipo de trabajo asignado se ajusta ágilmente a los cambios de la operación?',
					value: 'preguntaCinco',
				},
				{
					label: '¿El equipo de trabajo asignado genera propuestas que agregan valor a la operación?',
					value: 'preguntaSeis',
				},
				{
					label: '¿El equipo de trabajo asignado es apreciado por el público objetivo de su organización?',
					value: 'preguntaSiete',
				},
				{
					label: '¿Para su organización es valiosa la gestión de SPIRA y su equipo de trabajo en el logro de sus objetivos?',
					value: 'preguntaOcho',
				},
				{
					label: '¿SPIRA ha cumplido con la oferta de valor pactada en los acuerdos de servicio/contrato?',
					value: 'preguntaNueve',
				},
				{
					label: '¿En la operación se siente la presencia de SPIRA como proveedor y como empresa?',
					value: 'preguntaDiez',
				},
				{
					label: '¿El equipo de trabajo asignado ha generado empatía en todo el proceso de implementación del proyecto?',
					value: 'preguntaOnce',
				},
				{
					label: '¿El equipo de trabajo asignado definió un plan o cronograma de trabajo, donde especificó las fechas de entrega(parcial o total) del proyecto y criterios de aceptación de este por parte del cliente?',
					value: 'preguntaDoce',
				},
				{
					label: '¿El equipo de trabajo asignado genera confianza por la claridad y conocimiento de las necesidades y expectativas del proyecto?',
					value: 'preguntaTrece',
				},
				{
					label: '¿El equipo de trabajo asignado presentó una secuencia de actividades detallada e ilustrativa del proyecto a desarrollar?',
					value: 'preguntaCatorce',
				},
				{
					label: '¿El equipo SPIRA cuenta con los recursos humanos, técnicos y metodológicos necesarios para la ejecución del proyecto?',
					value: 'preguntaQuince',
				},
				{
					label: '¿El equipo de trabajo asignado demuestra tener los conocimientos y experiencia requeridos para la ejecución del proyecto?',
					value: 'preguntaDieciseis',
				},
				{
					label: '¿El equipo de trabajo asignado realiza un seguimiento permanente y periódico del proyecto, de modo que se atiendan las inquietudes y requerimientos solicitados?',
					value: 'preguntaDiecisiete',
				},
				{
					label: '¿El equipo de trabajo asignado hace las entregas pactadas con calidad y de acuerdo con las expectativas planteadas al inicio del proyecto?',
					value: 'preguntaDieciocho',
				},
				{
					label: '¿La solución presentada cumple con sus expectativas en términos de calidad e innovación?',
					value: 'preguntaDiecinueve',
				},
				{
					label: 'En una escala de 1 a 10, ¿En qué medida estaría dispuesto a recomendar SPIRA a sus conocidos?',
					value: 'preguntaVeinte',
				},
				{ label: 'Fecha de registro', value: 'fechaRegistro' },
			],
			content: array,
		},
	];

	let settings = {
		fileName: `Satisfacción-Operaciones-${fecha}`, // Name of the resulting spreadsheet
		extraLength: 3, // A bigger number means that columns will be wider
		writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
	};

	xlsx(data, settings);
}

export async function reporteUsuarios() {
	const results = await getCollections('usuarios');
	const fecha = cambiarTextos(getDate());

	let data = [
		{
			sheet: 'USuarios',
			columns: [
				{ label: 'Nombre', value: 'nombre' },
				{ label: 'Correo electrónico', value: 'correo' },
				{ label: 'Rol', value: 'rol' },
				{ label: 'Teléfono', value: 'telefono' },
				{ label: 'País', value: 'pais' },
				{ label: 'Fecha de registro', value: 'fechaRegistro' },
			],
			content: results,
		},
	];

	let settings = {
		fileName: `Usuarios-${fecha}`, // Name of the resulting spreadsheet
		extraLength: 3, // A bigger number means that columns will be wider
		writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
	};

	xlsx(data, settings);
}

export async function reporteConsultores() {
	const results = await getCollections('consultores');
	const fecha = cambiarTextos(getDate());

	let data = [
		{
			sheet: 'Consultores',
			columns: [
				{ label: 'Nombre', value: 'nombre' },
				{ label: 'Cargo', value: 'cargo' },
				{ label: 'Correo electrónico', value: 'correo' },
				{ label: 'País', value: 'pais' },
				{ label: 'Fecha de registro', value: 'fechaRegistro' },
			],
			content: results,
		},
	];

	let settings = {
		fileName: `Consultores-${fecha}`, // Name of the resulting spreadsheet
		extraLength: 3, // A bigger number means that columns will be wider
		writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
	};

	xlsx(data, settings);
}

export async function reporteConsultoresExcel(id) {
	let ganadas = [];
	let perdidas = [];
	let array = [];

	const resultsGanadas = await getCollections('satisfaccionGeneralG');
	const resultsPerdidas = await getCollections('satisfaccionGeneral');
	Object.values(resultsGanadas).map((val) => {
		array.push({ ...val, perdidas: true });
	});
	Object.values(resultsPerdidas).map((val) => {
		array.push({ ...val, ganadas: true });
	});
	const orderData = groupBy(array, 'preguntaCuatro');

	Object.keys(orderData).map((key) => {
		if (key === id) {
			const values = orderData[key];
			if (values) {
				const valuess = Object.keys(values);
				Object.values(valuess).map((val) => {
					const data = orderData[key][val];
					if (data.ganadas) {
						perdidas.push({ ...data, nombre: id });
					}
					if (data.perdidas) {
						ganadas.push({ ...data, nombre: id });
					}
				});
			}
		}
	});

	const fecha = cambiarTextos(getDate());

	let data = [
		{
			sheet: 'Ganadas',
			columns: [
				{ label: 'Nombre', value: 'nombre' },
				{
					label: 'Por favor indique la empresa a la que pertenece',
					value: 'preguntaUno',
				},
				{ label: 'País de Operación del Cliente', value: 'preguntaDos' },
				{ label: 'País de Consultor Comercial', value: 'preguntaTres' },
				{
					label: 'Por favor seleccione al consultor comercial. Si su respuesta es Otro por favor indique el nombre.',
					value: 'preguntaCuatro',
				},
				{
					label: 'Por favor indique el proyecto o propuesta a evaluar',
					value: 'preguntaCinco',
				},
				{
					label: '¿El asesor comercial fue cordial durante todo el proceso comercial?',
					value: 'preguntaSeis',
				},
				{
					label: '¿El asesor comercial fue puntual en las citas acordadas?',
					value: 'preguntaSiete',
				},
				{
					label: '¿El asesor comercial realizó la presentación de Spira y le permitió entender con claridad los servicios que prestamos, nuestra experiencia y casos de éxito?',
					value: 'preguntaOcho',
				},
				{
					label: '¿El Asesor comercial demostró haber entendido las necesidades de su negocio /operación cuando presentó la propuesta?',
					value: 'preguntaNueve',
				},
				{
					label: '¿El asesor comercial le entregó la propuesta comercial en el tiempo acordado?',
					value: 'preguntaDiez',
				},
				{
					label: '¿Cuándo el asesor comercial presentó la oferta personalmente, le aclaró las dudas o inquietudes presentadas por su organización?',
					value: 'preguntaOnce',
				},
				{
					label: '¿El asesor comercial evidenció los beneficios que obtendrá su organización en el aumento del desempeño y/o el cumplimiento de sus objetivos?',
					value: 'preguntaDoce',
				},
				{
					label: '¿La propuesta comercial fue clara y respondió a sus necesidades?',
					value: 'preguntaTrece',
				},
				{
					label: '¿Después de la presentación de la propuesta, el asesor comercial mantuvo un permanente contacto e hizo seguimiento de esta?',
					value: 'preguntaCatorce',
				},
				{
					label: 'En una escala de 0 a 10, ¿En qué medida estaría dispuesto a recomendar SPIRA a sus conocidos?',
					value: 'preguntaQuince',
				},
			],
			content: ganadas,
		},
		{
			sheet: 'Perdidas',
			columns: [
				{ label: 'Nombre', value: 'nombre' },
				{
					label: 'Por favor indique la empresa a la que pertenece',
					value: 'preguntaUno',
				},
				{ label: 'País de Operación del Cliente', value: 'preguntaDos' },
				{ label: 'País de Consultor Comercial', value: 'preguntaTres' },
				{
					label: 'Por favor seleccione al consultor comercial. Si su respuesta es Otro por favor indique el nombre.',
					value: 'preguntaCuatro',
				},
				{
					label: 'Por favor indique el proyecto o propuesta a evaluar',
					value: 'preguntaCinco',
				},
				{
					label: '¿El asesor comercial fue cordial durante todo el proceso comercial?',
					value: 'preguntaSeis',
				},
				{
					label: '¿El asesor comercial fue puntual en las citas acordadas?',
					value: 'preguntaSiete',
				},
				{
					label: '¿El asesor comercial realizó la presentación de Spira y le permitió entender con claridad los servicios que prestamos, nuestra experiencia y casos de éxito?',
					value: 'preguntaOcho',
				},
				{
					label: '¿El Asesor comercial demostró haber entendido las necesidades de su negocio /operación cuando presentó la propuesta?',
					value: 'preguntaNueve',
				},
				{
					label: '¿El asesor comercial le entregó la propuesta comercial en el tiempo acordado?',
					value: 'preguntaDiez',
				},
				{
					label: '¿Cuándo el asesor comercial presentó la oferta personalmente, le aclaró las dudas o inquietudes presentadas por su organización?',
					value: 'preguntaOnce',
				},
				{
					label: '¿El asesor comercial evidenció los beneficios que obtendrá su organización en el aumento del desempeño y/o el cumplimiento de sus objetivos?',
					value: 'preguntaDoce',
				},
				{
					label: '¿La propuesta comercial fue clara y respondió a sus necesidades?',
					value: 'preguntaTrece',
				},
				{
					label: '¿Después de la presentación de la propuesta, el asesor comercial mantuvo un permanente contacto e hizo seguimiento de esta?',
					value: 'preguntaCatorce',
				},
				{
					label: 'En una escala de 0 a 10, ¿En qué medida estaría dispuesto a recomendar SPIRA a sus conocidos?',
					value: 'preguntaQuince',
				},
			],
			content: perdidas,
		},
	];

	let settings = {
		fileName: `${id}-${fecha}`, // Name of the resulting spreadsheet
		extraLength: 3, // A bigger number means that columns will be wider
		writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
	};

	xlsx(data, settings);
}

export async function SatisfaccionOperativa() {
	let array = [];
	let array11 = [];
	let array14 = [];
	const results = await getCollections('pruebas');

	Object.values(results).map((val) => {
		if (val.status === 'Terminado') {
			// pregunta11
			if (val.preguntaOnce['opcion1'] !== '') {
				array11.push(val.preguntaOnce.opcion1);
			}
			if (val.preguntaOnce['opcion2'] !== '') {
				array11.push(val.preguntaOnce.opcion2);
			}
			if (val.preguntaOnce['opcion3'] !== '') {
				array11.push(val.preguntaOnce.opcion3);
			}
			if (val.preguntaOnce['opcion4'] !== '') {
				array11.push(val.preguntaOnce.opcion4);
			}
			if (val.preguntaOnce['opcion5'] !== '') {
				array11.push(val.preguntaOnce.opcion5);
			}
			if (val.preguntaOnce['opcion6'] !== '') {
				array11.push(val.preguntaOnce.opcion6);
			}
			if (val.preguntaOnce['opcion7'] !== '') {
				array11.push(val.preguntaOnce.opcion7);
			}
			if (val.preguntaOnce['opcion8'] !== '') {
				array11.push(val.preguntaOnce.otro);
			}
			// pregunta12
			if (val.preguntaCatorce['opcion1'] !== '') {
				array14.push(val.preguntaCatorce.opcion1);
			}
			if (val.preguntaCatorce['opcion2'] !== '') {
				array14.push(val.preguntaCatorce.opcion2);
			}
			if (val.preguntaCatorce['opcion3'] !== '') {
				array14.push(val.preguntaCatorce.opcion3);
			}
			if (val.preguntaCatorce['opcion4'] !== '') {
				array14.push(val.preguntaCatorce.opcion4);
			}
			if (val.preguntaCatorce['opcion5'] !== '') {
				array14.push(val.preguntaCatorce.opcion5);
			}
			if (val.preguntaCatorce['opcion6'] !== '') {
				array14.push(val.preguntaCatorce.opcion6);
			}
			if (val.preguntaCatorce['opcion7'] !== '') {
				array14.push(val.preguntaCatorce.opcion7);
			}
			if (val.preguntaCatorce['opcion8'] !== '') {
				array14.push(val.preguntaCatorce.otro);
			}

			array.push({
				...val,
				preguntaCinco: parseInt(val.preguntaSeis),
				preguntaSeis: parseInt(val.preguntaSeis),
				preguntaSiete: parseInt(val.preguntaSiete),
				preguntaOcho: parseInt(val.preguntaOcho),
				preguntaNueve: parseInt(val.preguntaNueve),
				preguntaDiez: parseInt(val.preguntaDiez),
				preguntaOnce: array11.toString(),
				preguntaDoce: parseInt(val.preguntaDoce),
				preguntaTrece: parseInt(val.preguntaTrece),
				preguntaCatorce: array14.toString(),
				preguntaQuince: parseInt(val.preguntaQuince),
				preguntaDieciseis: parseInt(val.preguntaDieciseis),
				preguntaDiecisiete: val.preguntaDiecisiete,
			});
		}
	});
	const fecha = cambiarTextos(getDate());
	let data = [
		{
			sheet: 'Satisfacción operativa',
			columns: [
				{
					label: 'Por favor indique la empresa a la que pertenece',
					value: 'preguntaUno',
				},
				{ label: 'País de Operación del Cliente', value: 'preguntaDos' },
				{ label: 'País de consultor comercial', value: 'preguntaTres' },
				{
					label: 'Por favor indique el nombre del proyecto a evaluar',
					value: 'preguntaCuatro',
				},
				{
					label: 'El equipo Spira cumplió con la oferta presentada en la propuesta comercial',
					value: 'preguntaCinco',
				},
				{
					label: '¿El equipo Spira mostró experiencia y dominio técnico requerido para cumplir la oferta de valor?',
					value: 'preguntaSeis',
				},
				{
					label: 'Las propuestas presentadas responden a la estrategia, retos y necesidades de su área /organización',
					value: 'preguntaSiete',
				},
				{
					label: 'Las propuestas presentadas responden a las necesidades y preferencias del público objetivo',
					value: 'preguntaOcho',
				},
				{
					label: 'El equipo Spira mantuvo comunicación y acompañamiento durante todo el proyecto',
					value: 'preguntaNueve',
				},
				{
					label: 'El equipo Spira mostró flexibilidad, adaptación y brindó alternativas ante el cambio y/o nuevos requerimientos',
					value: 'preguntaDiez',
				},
				{
					label: 'Qué elementos reconoce en el SERVICIO brindado por el equipo SPIRA? Seleccione 1 o varios',
					value: 'preguntaOnce',
				},
				{
					label: 'Fue clara la secuencia de actividades e hitos para desarrollar el proyecto',
					value: 'preguntaDoce',
				},
				{
					label: 'Se comunicaron los avances y riesgos del proyecto con oportunidad',
					value: 'preguntaTrece',
				},
				{
					label: '¿Cómo describe las ejecuciones/entregables desarrollados por el equipo Spira? Seleccione 1 o varios',
					value: 'preguntaCatorce',
				},
				{
					label: '¿Qué tan satisfecho está con el trabajo del equipo SPIRA y el resultado de su proyecto?',
					value: 'preguntaQuince',
				},
				{
					label: 'En una escala de 1 a 10, ¿En qué medida estaría dispuesto a recomendar SPIRA a sus conocidos?',
					value: 'preguntaDieciseis',
				},
				{
					label: '¿Desea expresar algún RECONOCIMIENTO, SUGERENCIA Y/O RETROALIMENTACIÓN? Por favor, escríbala a continuación.',
					value: 'preguntaDiecisiete',
				},

				{ label: 'Fecha de registro', value: 'fechaRegistroConsultor' },
				{ label: 'Fecha de culminación', value: 'fechaRegistroCliente' },
			],
			content: array,
		},
	];

	let settings = {
		fileName: `Satisfacción-operativa-${fecha}`, // Name of the resulting spreadsheet
		extraLength: 3, // A bigger number means that columns will be wider
		writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
	};

	xlsx(data, settings);
}

export async function SatisfaccionComercial() {
	let array = [];
	const results = await getCollections('encuestaDeSatisfaccionComercial');
	Object.values(results).map((val) => {
		if (val.status === 'Terminado') {
			array.push({
				...val,
				preguntaSeis: parseInt(val.preguntaSeis),
				preguntaSiete: parseInt(val.preguntaSiete),
				preguntaOcho: parseInt(val.preguntaOcho),
				preguntaNueve: parseInt(val.preguntaNueve),
				preguntaDiez: parseInt(val.preguntaDiez),
				preguntaOnce: parseInt(val.preguntaOnce),
				preguntaDoce: parseInt(val.preguntaDoce),
				preguntaTrece: parseInt(val.preguntaTrece),
				preguntaCatorce: parseInt(val.preguntaCatorce),
			});
		}
	});
	const fecha = cambiarTextos(getDate());
	let data = [
		{
			sheet: 'Satisfacción comercial',
			columns: [
				{
					label: 'Por favor indique la empresa a la que pertenece',
					value: 'preguntaUno',
				},
				{ label: 'País de Operación del Cliente', value: 'preguntaDos' },
				{ label: 'País de consultor comercial', value: 'preguntaTres' },
				{
					label: 'Por favor indique el nombre del proyecto a evaluar',
					value: 'preguntaCuatro',
				},
				{
					label: '¿El Consultor Comercial cumplió en calidad con los compromisos adquiridos durante todo el proceso? (propuestas, citas, entregas, etc.)',
					value: 'preguntaCinco',
				},
				{
					label: '¿El Consultor Comercial cumplió en tiempo con los compromisos adquiridos durante todo el proceso? (propuestas, citas, entregas, etc.)',
					value: 'preguntaSeis',
				},
				{
					label: '¿El Consultor Comercial se tomo el tiempo de entender las necesidades de su negocio /operación cuando presentó la propuesta comercial?',
					value: 'preguntaSiete',
				},
				{
					label: '¿El Consultor Comercial evidenció los beneficios que obtendrá su organización en el aumento del desempeño y/o el cumplimiento de sus objetivos, a través de la propuesta comercial presentada?',
					value: 'preguntaOcho',
				},
				{
					label: '¿El Consultor Comercial presentó una propuesta que respondía a las necesidades planteadas?',
					value: 'preguntaNueve',
				},
				{
					label: '¿El Consultor Comercial mantuvo un permanente contacto e hizo seguimiento de la propuesta comercial presentada?',
					value: 'preguntaDiez',
				},
				{
					label: '¿El Consultor Comercial transmitió una imagen profesional correcta y agradable durante el proceso?',
					value: 'preguntaOnce',
				},
				{
					label: '¿El Consultor Comercial se comunicó de manera efectiva en el proceso?',
					value: 'preguntaDoce',
				},
				{
					label: '¿El Consultor Comercial creo un espacio agradable que hizo fácil la interacción?',
					value: 'preguntaTrece',
				},
				{
					label: '¿Le gustaría seguir siendo atendido por el Consultor Comercial asignado?',
					value: 'preguntaCatorce',
				},
				{
					label: 'En una escala de 0 a 10, ¿En qué medida estaría dispuesto a recomendar SPIRA a sus conocidos?',
					value: 'preguntaQuince',
				},
				{
					label: 'Comentarios:',
					value: 'comentarios',
				},

				{ label: 'Fecha de registro', value: 'fechaRegistroConsultor' },
				{ label: 'Fecha de culminación', value: 'fechaRegistroCliente' },
			],
			content: array,
		},
	];

	let settings = {
		fileName: `Satisfacción-comercfial-${fecha}`, // Name of the resulting spreadsheet
		extraLength: 3, // A bigger number means that columns will be wider
		writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
	};

	xlsx(data, settings);
}

export async function descargarReporte(id, nombre) {
	let arrayGeneral = [];
	if (id !== 'd23bca0d8813a46d07288313e2b65084') {
		const result = await getCollections(`${id}`);
		Object.values(result).map((val) => {
			arrayGeneral.push({
				pregunta1: parseInt(val.pregunta1),
				pregunta2: parseInt(val.pregunta2),
				pregunta3: parseInt(val.pregunta3),
				pregunta4: parseInt(val.pregunta4),
				pregunta5: parseInt(val.pregunta5),
				pregunta6: parseInt(val.pregunta6),
				pregunta7: parseInt(val.pregunta7),
				pregunta8: parseInt(val.pregunta8),
				pregunta9: parseInt(val.pregunta9),
				pregunta10: parseInt(val.pregunta10),
				pregunta11: parseInt(val.pregunta11),
				pregunta12: parseInt(val.pregunta12),
				pregunta13: parseInt(val.pregunta13),
				pregunta14: parseInt(val.pregunta14),
				pregunta15: parseInt(val.pregunta15),
				pregunta16: parseInt(val.pregunta16),
				pregunta17: parseInt(val.pregunta17),
				pregunta18: parseInt(val.pregunta18),
				pregunta19: parseInt(val.pregunta19),
				pregunta20: parseInt(val.pregunta20),
				pregunta21: parseInt(val.pregunta21),
				pregunta22: parseInt(val.pregunta22),
				pregunta23: parseInt(val.pregunta23),
				pregunta24: parseInt(val.pregunta24),
				// pregunta25: parseInt(val.pregunta25),
				pregunta26: parseInt(val.pregunta26),
				pregunta27: parseInt(val.pregunta27),
				pregunta28: parseInt(val.pregunta28),
				pregunta29: val.pregunta29,
				area: nombre,
				fechaDeRegistro: val.fechaDeRegistro,
			});
		});
	} else {
		const result1 = await getCollections(`21232f297a57a5a743894a0e4a801fc3`);
		Object.values(result1).map((val) => {
			arrayGeneral.push({
				pregunta1: parseInt(val.pregunta1),
				pregunta2: parseInt(val.pregunta2),
				pregunta3: parseInt(val.pregunta3),
				pregunta4: parseInt(val.pregunta4),
				pregunta5: parseInt(val.pregunta5),
				pregunta6: parseInt(val.pregunta6),
				pregunta7: parseInt(val.pregunta7),
				pregunta8: parseInt(val.pregunta8),
				pregunta9: parseInt(val.pregunta9),
				pregunta10: parseInt(val.pregunta10),
				pregunta11: parseInt(val.pregunta11),
				pregunta12: parseInt(val.pregunta12),
				pregunta13: parseInt(val.pregunta13),
				pregunta14: parseInt(val.pregunta14),
				pregunta15: parseInt(val.pregunta15),
				pregunta16: parseInt(val.pregunta16),
				pregunta17: parseInt(val.pregunta17),
				pregunta18: parseInt(val.pregunta18),
				pregunta19: parseInt(val.pregunta19),
				pregunta20: parseInt(val.pregunta20),
				pregunta21: parseInt(val.pregunta21),
				pregunta22: parseInt(val.pregunta22),
				pregunta23: parseInt(val.pregunta23),
				pregunta24: parseInt(val.pregunta24),
				// pregunta25: parseInt(val.pregunta25),
				pregunta26: parseInt(val.pregunta26),
				pregunta27: parseInt(val.pregunta27),
				pregunta28: parseInt(val.pregunta28),
				pregunta29: val.pregunta29,
				area: 'Administrativos',
				fechaDeRegistro: val.fechaDeRegistro,
			});
		});
		const result2 = await getCollections(`b467cbda88db8941101d780ed8f19fe8`);
		Object.values(result2).map((val) => {
			arrayGeneral.push({
				pregunta1: parseInt(val.pregunta1),
				pregunta2: parseInt(val.pregunta2),
				pregunta3: parseInt(val.pregunta3),
				pregunta4: parseInt(val.pregunta4),
				pregunta5: parseInt(val.pregunta5),
				pregunta6: parseInt(val.pregunta6),
				pregunta7: parseInt(val.pregunta7),
				pregunta8: parseInt(val.pregunta8),
				pregunta9: parseInt(val.pregunta9),
				pregunta10: parseInt(val.pregunta10),
				pregunta11: parseInt(val.pregunta11),
				pregunta12: parseInt(val.pregunta12),
				pregunta13: parseInt(val.pregunta13),
				pregunta14: parseInt(val.pregunta14),
				pregunta15: parseInt(val.pregunta15),
				pregunta16: parseInt(val.pregunta16),
				pregunta17: parseInt(val.pregunta17),
				pregunta18: parseInt(val.pregunta18),
				pregunta19: parseInt(val.pregunta19),
				pregunta20: parseInt(val.pregunta20),
				pregunta21: parseInt(val.pregunta21),
				pregunta22: parseInt(val.pregunta22),
				pregunta23: parseInt(val.pregunta23),
				pregunta24: parseInt(val.pregunta24),
				// pregunta25: parseInt(val.pregunta25),
				pregunta26: parseInt(val.pregunta26),
				pregunta27: parseInt(val.pregunta27),
				pregunta28: parseInt(val.pregunta28),
				pregunta29: val.pregunta29,
				area: 'Arriva',
				fechaDeRegistro: val.fechaDeRegistro,
			});
		});
		const result3 = await getCollections(`0ded17b2e1260363c5f39dff73244048`);
		Object.values(result3).map((val) => {
			arrayGeneral.push({
				pregunta1: parseInt(val.pregunta1),
				pregunta2: parseInt(val.pregunta2),
				pregunta3: parseInt(val.pregunta3),
				pregunta4: parseInt(val.pregunta4),
				pregunta5: parseInt(val.pregunta5),
				pregunta6: parseInt(val.pregunta6),
				pregunta7: parseInt(val.pregunta7),
				pregunta8: parseInt(val.pregunta8),
				pregunta9: parseInt(val.pregunta9),
				pregunta10: parseInt(val.pregunta10),
				pregunta11: parseInt(val.pregunta11),
				pregunta12: parseInt(val.pregunta12),
				pregunta13: parseInt(val.pregunta13),
				pregunta14: parseInt(val.pregunta14),
				pregunta15: parseInt(val.pregunta15),
				pregunta16: parseInt(val.pregunta16),
				pregunta17: parseInt(val.pregunta17),
				pregunta18: parseInt(val.pregunta18),
				pregunta19: parseInt(val.pregunta19),
				pregunta20: parseInt(val.pregunta20),
				pregunta21: parseInt(val.pregunta21),
				pregunta22: parseInt(val.pregunta22),
				pregunta23: parseInt(val.pregunta23),
				pregunta24: parseInt(val.pregunta24),
				// pregunta25: parseInt(val.pregunta25),
				pregunta26: parseInt(val.pregunta26),
				pregunta27: parseInt(val.pregunta27),
				pregunta28: parseInt(val.pregunta28),
				pregunta29: val.pregunta29,
				area: 'Comercial y Mercadeo',
				fechaDeRegistro: val.fechaDeRegistro,
			});
		});
		const result4 = await getCollections(`cd1727892c8a35d460d4dace151c36f1`);
		Object.values(result4).map((val) => {
			arrayGeneral.push({
				pregunta1: parseInt(val.pregunta1),
				pregunta2: parseInt(val.pregunta2),
				pregunta3: parseInt(val.pregunta3),
				pregunta4: parseInt(val.pregunta4),
				pregunta5: parseInt(val.pregunta5),
				pregunta6: parseInt(val.pregunta6),
				pregunta7: parseInt(val.pregunta7),
				pregunta8: parseInt(val.pregunta8),
				pregunta9: parseInt(val.pregunta9),
				pregunta10: parseInt(val.pregunta10),
				pregunta11: parseInt(val.pregunta11),
				pregunta12: parseInt(val.pregunta12),
				pregunta13: parseInt(val.pregunta13),
				pregunta14: parseInt(val.pregunta14),
				pregunta15: parseInt(val.pregunta15),
				pregunta16: parseInt(val.pregunta16),
				pregunta17: parseInt(val.pregunta17),
				pregunta18: parseInt(val.pregunta18),
				pregunta19: parseInt(val.pregunta19),
				pregunta20: parseInt(val.pregunta20),
				pregunta21: parseInt(val.pregunta21),
				pregunta22: parseInt(val.pregunta22),
				pregunta23: parseInt(val.pregunta23),
				pregunta24: parseInt(val.pregunta24),
				// pregunta25: parseInt(val.pregunta25),
				pregunta26: parseInt(val.pregunta26),
				pregunta27: parseInt(val.pregunta27),
				pregunta28: parseInt(val.pregunta28),
				pregunta29: val.pregunta29,
				area: 'Consultoria',
				fechaDeRegistro: val.fechaDeRegistro,
			});
		});
		const result5 = await getCollections(`d9df2de23ad53932be36331a9be625be`);
		Object.values(result5).map((val) => {
			arrayGeneral.push({
				pregunta1: parseInt(val.pregunta1),
				pregunta2: parseInt(val.pregunta2),
				pregunta3: parseInt(val.pregunta3),
				pregunta4: parseInt(val.pregunta4),
				pregunta5: parseInt(val.pregunta5),
				pregunta6: parseInt(val.pregunta6),
				pregunta7: parseInt(val.pregunta7),
				pregunta8: parseInt(val.pregunta8),
				pregunta9: parseInt(val.pregunta9),
				pregunta10: parseInt(val.pregunta10),
				pregunta11: parseInt(val.pregunta11),
				pregunta12: parseInt(val.pregunta12),
				pregunta13: parseInt(val.pregunta13),
				pregunta14: parseInt(val.pregunta14),
				pregunta15: parseInt(val.pregunta15),
				pregunta16: parseInt(val.pregunta16),
				pregunta17: parseInt(val.pregunta17),
				pregunta18: parseInt(val.pregunta18),
				pregunta19: parseInt(val.pregunta19),
				pregunta20: parseInt(val.pregunta20),
				pregunta21: parseInt(val.pregunta21),
				pregunta22: parseInt(val.pregunta22),
				pregunta23: parseInt(val.pregunta23),
				pregunta24: parseInt(val.pregunta24),
				// pregunta25: parseInt(val.pregunta25),
				pregunta26: parseInt(val.pregunta26),
				pregunta27: parseInt(val.pregunta27),
				pregunta28: parseInt(val.pregunta28),
				pregunta29: val.pregunta29,
				area: 'Diseño gráfico',
				fechaDeRegistro: val.fechaDeRegistro,
			});
		});
		const result6 = await getCollections(`703fca8be78bae21d5b9fa6c0dd6b49e`);
		Object.values(result6).map((val) => {
			arrayGeneral.push({
				pregunta1: parseInt(val.pregunta1),
				pregunta2: parseInt(val.pregunta2),
				pregunta3: parseInt(val.pregunta3),
				pregunta4: parseInt(val.pregunta4),
				pregunta5: parseInt(val.pregunta5),
				pregunta6: parseInt(val.pregunta6),
				pregunta7: parseInt(val.pregunta7),
				pregunta8: parseInt(val.pregunta8),
				pregunta9: parseInt(val.pregunta9),
				pregunta10: parseInt(val.pregunta10),
				pregunta11: parseInt(val.pregunta11),
				pregunta12: parseInt(val.pregunta12),
				pregunta13: parseInt(val.pregunta13),
				pregunta14: parseInt(val.pregunta14),
				pregunta15: parseInt(val.pregunta15),
				pregunta16: parseInt(val.pregunta16),
				pregunta17: parseInt(val.pregunta17),
				pregunta18: parseInt(val.pregunta18),
				pregunta19: parseInt(val.pregunta19),
				pregunta20: parseInt(val.pregunta20),
				pregunta21: parseInt(val.pregunta21),
				pregunta22: parseInt(val.pregunta22),
				pregunta23: parseInt(val.pregunta23),
				pregunta24: parseInt(val.pregunta24),
				// pregunta25: parseInt(val.pregunta25),
				pregunta26: parseInt(val.pregunta26),
				pregunta27: parseInt(val.pregunta27),
				pregunta28: parseInt(val.pregunta28),
				pregunta29: val.pregunta29,
				area: 'Diseño instruccional',
				fechaDeRegistro: val.fechaDeRegistro,
			});
		});
		const result7 = await getCollections(`8cb6ceae13ed574a8f8cbec2e8238ee2`);
		Object.values(result7).map((val) => {
			arrayGeneral.push({
				pregunta1: parseInt(val.pregunta1),
				pregunta2: parseInt(val.pregunta2),
				pregunta3: parseInt(val.pregunta3),
				pregunta4: parseInt(val.pregunta4),
				pregunta5: parseInt(val.pregunta5),
				pregunta6: parseInt(val.pregunta6),
				pregunta7: parseInt(val.pregunta7),
				pregunta8: parseInt(val.pregunta8),
				pregunta9: parseInt(val.pregunta9),
				pregunta10: parseInt(val.pregunta10),
				pregunta11: parseInt(val.pregunta11),
				pregunta12: parseInt(val.pregunta12),
				pregunta13: parseInt(val.pregunta13),
				pregunta14: parseInt(val.pregunta14),
				pregunta15: parseInt(val.pregunta15),
				pregunta16: parseInt(val.pregunta16),
				pregunta17: parseInt(val.pregunta17),
				pregunta18: parseInt(val.pregunta18),
				pregunta19: parseInt(val.pregunta19),
				pregunta20: parseInt(val.pregunta20),
				pregunta21: parseInt(val.pregunta21),
				pregunta22: parseInt(val.pregunta22),
				pregunta23: parseInt(val.pregunta23),
				pregunta24: parseInt(val.pregunta24),
				// pregunta25: parseInt(val.pregunta25),
				pregunta26: parseInt(val.pregunta26),
				pregunta27: parseInt(val.pregunta27),
				pregunta28: parseInt(val.pregunta28),
				pregunta29: val.pregunta29,
				area: 'Heineken',
				fechaDeRegistro: val.fechaDeRegistro,
			});
		});
		const result8 = await getCollections(`527c128fd90c90859a5ca1617a2cd23d`);
		Object.values(result8).map((val) => {
			arrayGeneral.push({
				pregunta1: parseInt(val.pregunta1),
				pregunta2: parseInt(val.pregunta2),
				pregunta3: parseInt(val.pregunta3),
				pregunta4: parseInt(val.pregunta4),
				pregunta5: parseInt(val.pregunta5),
				pregunta6: parseInt(val.pregunta6),
				pregunta7: parseInt(val.pregunta7),
				pregunta8: parseInt(val.pregunta8),
				pregunta9: parseInt(val.pregunta9),
				pregunta10: parseInt(val.pregunta10),
				pregunta11: parseInt(val.pregunta11),
				pregunta12: parseInt(val.pregunta12),
				pregunta13: parseInt(val.pregunta13),
				pregunta14: parseInt(val.pregunta14),
				pregunta15: parseInt(val.pregunta15),
				pregunta16: parseInt(val.pregunta16),
				pregunta17: parseInt(val.pregunta17),
				pregunta18: parseInt(val.pregunta18),
				pregunta19: parseInt(val.pregunta19),
				pregunta20: parseInt(val.pregunta20),
				pregunta21: parseInt(val.pregunta21),
				pregunta22: parseInt(val.pregunta22),
				pregunta23: parseInt(val.pregunta23),
				pregunta24: parseInt(val.pregunta24),
				// pregunta25: parseInt(val.pregunta25),
				pregunta26: parseInt(val.pregunta26),
				pregunta27: parseInt(val.pregunta27),
				pregunta28: parseInt(val.pregunta28),
				pregunta29: val.pregunta29,
				area: 'Operaciones',
				fechaDeRegistro: val.fechaDeRegistro,
			});
		});
		const result9 = await getCollections(`a11b82bfe1571abb4e53be6b80be8714`);
		Object.values(result9).map((val) => {
			arrayGeneral.push({
				pregunta1: parseInt(val.pregunta1),
				pregunta2: parseInt(val.pregunta2),
				pregunta3: parseInt(val.pregunta3),
				pregunta4: parseInt(val.pregunta4),
				pregunta5: parseInt(val.pregunta5),
				pregunta6: parseInt(val.pregunta6),
				pregunta7: parseInt(val.pregunta7),
				pregunta8: parseInt(val.pregunta8),
				pregunta9: parseInt(val.pregunta9),
				pregunta10: parseInt(val.pregunta10),
				pregunta11: parseInt(val.pregunta11),
				pregunta12: parseInt(val.pregunta12),
				pregunta13: parseInt(val.pregunta13),
				pregunta14: parseInt(val.pregunta14),
				pregunta15: parseInt(val.pregunta15),
				pregunta16: parseInt(val.pregunta16),
				pregunta17: parseInt(val.pregunta17),
				pregunta18: parseInt(val.pregunta18),
				pregunta19: parseInt(val.pregunta19),
				pregunta20: parseInt(val.pregunta20),
				pregunta21: parseInt(val.pregunta21),
				pregunta22: parseInt(val.pregunta22),
				pregunta23: parseInt(val.pregunta23),
				pregunta24: parseInt(val.pregunta24),
				// pregunta25: parseInt(val.pregunta25),
				pregunta26: parseInt(val.pregunta26),
				pregunta27: parseInt(val.pregunta27),
				pregunta28: parseInt(val.pregunta28),
				pregunta29: val.pregunta29,
				area: 'Programación y video',
				fechaDeRegistro: val.fechaDeRegistro,
			});
		});
	}
	const fecha = cambiarTextos(getDate());
	let data = [
		{
			sheet: `Reporte - ${id !== 'd23bca0d8813a46d07288313e2b65084' ? `${nombre}` : `Spira méxico`}`,
			columns: [
				{
					label: 'Área',
					value: 'area',
				},
				{
					label: '¿Qué tan satisfecho/a estás con las tareas y responsabilidades de tu trabajo actual?',
					value: 'pregunta1',
				},
				{ label: '¿Consideras que tus habilidades y talentos son utilizados adecuadamente en tu trabajo?', value: 'pregunta2' },
				{ label: '¿Sientes que tu lider te proporciona la orientación y el apoyo necesario?', value: 'pregunta3' },
				{
					label: '¿Consideras que los líderes de la empresa inspiran y motivan a los empleados?',
					value: 'pregunta4',
				},
				{
					label: '¿Consideras que los líderes están abiertos a recibir retroalimentación y sugerencias?',
					value: 'pregunta5',
				},
				{
					label: '¿Consideras que la comunicación en tu equipo es abierta y efectiva?',
					value: 'pregunta6',
				},
				{
					label: '¿Consideras que la comunicación entre los diferentes departamentos es abierta y efectiva?',
					value: 'pregunta7',
				},
				{
					label: '¿Consideras que la empresa es transparente en sus decisiones y políticas?',
					value: 'pregunta8',
				},
				{
					label: '¿Cómo describirías la calidad de las relaciones entre los miembros de tu equipo de trabajo?',
					value: 'pregunta9',
				},
				{
					label: '¿Te sientes apoyado/a por tus compañeros de trabajo cuando enfrentas desafíos laborales?',
					value: 'pregunta10',
				},
				{
					label: '¿Te sientes adecuadamente reconocido/a por tus logros y contribuciones en el trabajo?',
					value: 'pregunta11',
				},
				{
					label: '¿Consideras que las recompensas que recibes (salario, beneficios, etc.) son justas en relación con tu desempeño?',
					value: 'pregunta12',
				},
				{
					label: '¿Tienes acceso a oportunidades de formación y desarrollo profesional en la empresa?',
					value: 'pregunta13',
				},
				{
					label: '¿Consideras que existen oportunidades claras de crecimiento y promoción dentro de la empresa?',
					value: 'pregunta14',
				},
				{
					label: '¿Tienes acceso a las herramientas y recursos necesarios para realizar tu trabajo de manera eficiente?',
					value: 'pregunta15',
				},
				{
					label: '¿La empresa promueve activamente la salud y el bienestar de sus empleados?',
					value: 'pregunta16',
				},
				{
					label: '¿Consideras que la empresa te ofrece la flexibilidad necesaria para equilibrar tu vida laboral y personal?',
					value: 'pregunta17',
				},
				{
					label: '¿Sientes que tu carga de trabajo te permite mantener un buen equilibrio entre tus responsabilidades laborales y personales?',
					value: 'pregunta18',
				},
				{
					label: '¿Hasta qué punto sientes que tienes la autonomía necesaria para tomar decisiones relacionadas con tu trabajo diario?',
					value: 'pregunta19',
				},
				{
					label: '¿Consideras que tu opinión es valorada y tomada en cuenta en las decisiones que afectan tu trabajo y el del equipo de trabajo?',
					value: 'pregunta20',
				},
				{
					label: '¿Te sientes alineado/a con los valores y el propósito de la empresa?',
					value: 'pregunta21',
				},
				{
					label: '¿Consideras que la empresa fomenta la innovación y la creatividad?',
					value: 'pregunta22',
				},
				{
					label: '¿Condideras que la empresa promueve la diversidad e inclusión en el trabajo?',
					value: 'pregunta23',
				},
				{
					label: '¿Hasta qué punto sientes que tu posición en la empresa es estable y segura a largo plazo?',
					value: 'pregunta24',
				},
				{
					label: '¿Con qué frecuencia te preocupas por la posibilidad de perder tu empleo en la empresa?',
					value: 'pregunta25',
				},
				{
					label: '¿Consideras que los recursos (por ejemplo, tiempo, presupuesto, oportunidades) se distribuyen de manera justa entre todos los empleados en la empresa?',
					value: 'pregunta26',
				},
				{
					label: '¿Crees que los procesos de toma de decisiones y las políticas de la empresa se aplican de manera justa y equitativa para todos los empleados?',
					value: 'pregunta27',
				},
				{
					label: 'De 0 a 10, recomendarias a un amigo y/o familiar trabajar en la empresa',
					value: 'pregunta28',
				},
				{
					label: '¿Existe algo especial o único en tu empresa que lo caracterice como un gran lugar para trabajar? Da Ejemplos específicos.',
					value: 'pregunta29',
				},
				{
					label: 'Fecha de registro',
					value: 'fechaDeRegistro',
				},
			],
			content: arrayGeneral,
		},
	];

	let settings = {
		fileName: `Encuesta clima organizacional-${id !== 'd23bca0d8813a46d07288313e2b65084' ? `${nombre}` : `Spira méxico`}-${fecha}`, // Name of the resulting spreadsheet
		extraLength: 3, // A bigger number means that columns will be wider
		writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
	};
	// console.log(arrayGeneral);

	xlsx(data, settings);
}
