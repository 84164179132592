const preguntas = [
	{
		pregunta: '1. ¿Qué tan satisfecho/a estás con las tareas y responsabilidades de tu trabajo actual?',
		seccion: 1,
		labelBD: 'pregunta1',
		escalaIzquierda: 'Nada satisfecho/a',
		escalaDerecha: 'Muy satisfecho/a',
	},
	{
		pregunta: '2. ¿Consideras que tus habilidades y talentos son tomados en cuenta y aprovechados adecuadamente en tu trabajo?',
		seccion: 1,
		labelBD: 'pregunta2',
		escalaIzquierda: 'Nada de acuerdo',
		escalaDerecha: 'Totalmente de acuerdo',
	},
	{
		pregunta: '3. ¿Sientes que tu lider te proporciona la orientación y el apoyo necesario?',
		seccion: 1,
		labelBD: 'pregunta3',
		escalaIzquierda: 'Nada de acuerdo',
		escalaDerecha: 'Totalmente de acuerdo',
	},
	{
		pregunta: '4. ¿Consideras que los líderes de la empresa inspiran y motivan a los colaboradores?',
		seccion: 1,
		labelBD: 'pregunta4',
		escalaIzquierda: 'Nada de acuerdo',
		escalaDerecha: 'Totalmente de acuerdo',
	},
	{
		pregunta: '5. ¿Consideras que los líderes están abiertos a recibir retroalimentación y sugerencias?',
		seccion: 1,
		labelBD: 'pregunta5',
		escalaIzquierda: 'Nada de acuerdo',
		escalaDerecha: 'Totalmente de acuerdo',
	},
	{
		pregunta: '6. ¿Consideras que la comunicación en tu equipo es abierta y efectiva?',
		seccion: 2,
		labelBD: 'pregunta6',
		escalaIzquierda: 'Nada de acuerdo',
		escalaDerecha: 'Totalmente de acuerdo',
	},
	{
		pregunta: '7. ¿Consideras que la comunicación entre las diferentes áreas es abierta y efectiva?',
		seccion: 2,
		labelBD: 'pregunta7',
		escalaIzquierda: 'Nada de acuerdo',
		escalaDerecha: 'Totalmente de acuerdo',
	},
	{
		pregunta: '8. ¿Consideras que la empresa comunica con transparecia y claridad sus decisiones y políticas?',
		seccion: 2,
		labelBD: 'pregunta8',
		escalaIzquierda: 'Nada de acuerdo',
		escalaDerecha: 'Totalmente de acuerdo',
	},
	{
		pregunta: '9. ¿Cómo describirías la relación e interacciones entre los miembros de tu equipo?',
		seccion: 2,
		labelBD: 'pregunta9',
		escalaIzquierda: 'Muy malas',
		escalaDerecha: 'Excelentes',
	},
	{
		pregunta: '10. ¿Te sientes apoyado/a por tus compañeros de trabajo cuando enfrentas desafíos laborales?',
		seccion: 2,
		labelBD: 'pregunta10',
		escalaIzquierda: 'Nada de acuerdo',
		escalaDerecha: 'Totalmente de acuerdo',
	},
	{
		pregunta: '11. ¿Consideras que tus logros y contribuciones en el trabajo se reconocen adecuadamente?',
		seccion: 3,
		labelBD: 'pregunta11',
		escalaIzquierda: 'Nada de acuerdo',
		escalaDerecha: 'Totalmente de acuerdo',
	},
	{
		pregunta: '12. ¿Consideras que las recompensas que recibes (salario, beneficios, etc.) son justas en relación con tu desempeño?',
		seccion: 3,
		labelBD: 'pregunta12',
		escalaIzquierda: 'Nada de acuerdo',
		escalaDerecha: 'Totalmente de acuerdo',
	},
	{
		pregunta: '13. ¿Tienes acceso a oportunidades de formación y desarrollo profesional en la empresa?',
		seccion: 3,
		labelBD: 'pregunta13',
		escalaIzquierda: 'Nada de acuerdo',
		escalaDerecha: 'Totalmente de acuerdo',
	},
	{
		pregunta: '14. ¿Consideras que existen oportunidades claras de crecimiento y promoción dentro de la empresa?',
		seccion: 3,
		labelBD: 'pregunta14',
		escalaIzquierda: 'Nada de acuerdo',
		escalaDerecha: 'Totalmente de acuerdo',
	},
	{
		pregunta: '15. ¿Tienes acceso a las herramientas y recursos necesarios para realizar tu trabajo de manera eficiente?',
		seccion: 3,
		labelBD: 'pregunta15',
		escalaIzquierda: 'Nada de acuerdo',
		escalaDerecha: 'Totalmente de acuerdo',
	},
	{
		pregunta: '16. ¿La empresa promueve activamente la salud y el bienestar de sus empleados?',
		seccion: 4,
		labelBD: 'pregunta16',
		escalaIzquierda: 'Nada de acuerdo',
		escalaDerecha: 'Totalmente de acuerdo',
	},
	{
		pregunta: '17. ¿Consideras que la empresa te ofrece la flexibilidad necesaria para equilibrar tu vida laboral y personal?',
		seccion: 4,
		labelBD: 'pregunta17',
		escalaIzquierda: 'Nada de acuerdo',
		escalaDerecha: 'Totalmente de acuerdo',
	},
	{
		pregunta: '18. ¿Sientes que tu carga de trabajo te permite mantener un buen equilibrio entre tus responsabilidades laborales y personales?',
		seccion: 4,
		labelBD: 'pregunta18',
		escalaIzquierda: 'Nada de acuerdo',
		escalaDerecha: 'Totalmente de acuerdo',
	},
	{
		pregunta: '19. ¿Te sientes con la autonomía y capacidad de decisión suficientes para desarrollar tus tareas diarias?',
		seccion: 4,
		labelBD: 'pregunta19',
		escalaIzquierda: 'Nada de acuerdo',
		escalaDerecha: 'Totalmente de acuerdo',
	},
	{
		pregunta: '20. ¿Consideras que la empresa toma en cuenta tu opinión en las decisiones que impactan tu trabajo o el de tu equipo?',
		seccion: 4,
		labelBD: 'pregunta20',
		escalaIzquierda: 'Nunca',
		escalaDerecha: 'Siempre',
	},
	{
		pregunta: '21. ¿Te sientes identificado/a con los valores y el propósito de la empresa? ',
		seccion: 5,
		labelBD: 'pregunta21',
		escalaIzquierda: 'Nada de acuerdo',
		escalaDerecha: 'Totalmente de acuerdo',
	},
	{
		pregunta: '22. ¿Consideras que la empresa fomenta la innovación y la creatividad?',
		seccion: 5,
		labelBD: 'pregunta22',
		escalaIzquierda: 'Nada de acuerdo',
		escalaDerecha: 'Totalmente de acuerdo',
	},
	{
		pregunta: '23. ¿Condideras que la empresa promueve la diversidad e inclusión en el trabajo?',
		seccion: 5,
		labelBD: 'pregunta23',
		escalaIzquierda: 'Nada de acuerdo',
		escalaDerecha: 'Totalmente de acuerdo',
	},
	{
		pregunta: '24. ¿Qué tan seguro/a te sientes respecto a la estabilidad de tu puesto de trabajo?',
		seccion: 5,
		labelBD: 'pregunta24',
		escalaIzquierda: 'Muy inseguro',
		escalaDerecha: 'Muy seguro',
	},
	{
		pregunta: '25. ¿Consideras que los recursos (por ejemplo, tiempo, presupuesto, oportunidades) se distribuyen de manera justa entre todos los empleados en la empresa?',
		seccion: 6,
		labelBD: 'pregunta26',
		escalaIzquierda: 'Nada de acuerdo',
		escalaDerecha: 'Totalmente de acuerdo',
	},
	{
		pregunta: '26. ¿Crees que los procesos de toma de decisiones y las políticas de la empresa se aplican de manera justa y equitativa para todos los empleados?',
		seccion: 6,
		labelBD: 'pregunta27',
		escalaIzquierda: 'Nada de acuerdo',
		escalaDerecha: 'Totalmente de acuerdo',
	},
];
export default preguntas;
