// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
	// apiKey: "AIzaSyAUyjoRY_fuckBP2sOnFw4-W7ycwtsqBhE",
	// authDomain: "spira-clima-organizacional.firebaseapp.com",
	// projectId: "spira-clima-organizacional",
	// storageBucket: "spira-clima-organizacional.appspot.com",
	// messagingSenderId: "594958456811",
	// appId: "1:594958456811:web:0e3ac3982c84efbaf4abae",
	apiKey: "AIzaSyAH3jgeBBFvMQcjctkLhD_Xa9ZS-mGgAU4",
	authDomain: "spira-encuesta-clima.firebaseapp.com",
	databaseURL: "https://spira-encuesta-clima-default-rtdb.firebaseio.com",
	projectId: "spira-encuesta-clima",
	storageBucket: "spira-encuesta-clima.appspot.com",
	messagingSenderId: "628076374409",
	appId: "1:628076374409:web:d7071fd7fe0aacf67c5958",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
export const auth = getAuth(app);
