const datos = [
	{
		titulo: "Diseño Gráfico",
		url: `${process.env.REACT_APP_HOST_PROD}/encuesta/${process.env.REACT_APP_BD_LINK_DG}`,
	},
	{
		titulo: "Diseño Instruccional",
		url: `${process.env.REACT_APP_HOST_PROD}/encuesta/${process.env.REACT_APP_BD_LINK_DI}`,
	},
	{
		titulo: "Comercial y Mercadeo",
		url: `${process.env.REACT_APP_HOST_PROD}/encuesta/${process.env.REACT_APP_BD_LINK_COMERCIAL_MERCADEO}`,
	},
	{
		titulo: "Programación y Video",
		url: `${process.env.REACT_APP_HOST_PROD}/encuesta/${process.env.REACT_APP_BD_LINK_PROG_VIDEO}`,
	},
	{
		titulo: "Consultoria",
		url: `${process.env.REACT_APP_HOST_PROD}/encuesta/${process.env.REACT_APP_BD_LINK_CONSULTORIA}`,
	},
	{
		titulo: "Heineken",
		url: `${process.env.REACT_APP_HOST_PROD}/encuesta/${process.env.REACT_APP_BD_LINK_HEINEKEN}`,
	},
	{
		titulo: "Operaciones",
		url: `${process.env.REACT_APP_HOST_PROD}/encuesta/${process.env.REACT_APP_BD_LINK_OPERACIONES}`,
	},

	{
		titulo: "Administrativos",
		url: `${process.env.REACT_APP_HOST_PROD}/encuesta/${process.env.REACT_APP_BD_LINK_ADMIN}`,
	},
];

export default datos;
