import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import uniqid from 'uniqid';
import { Container, Grid, RadioGroup, FormControlLabel, Radio, Button, TextField, FormControl } from '@mui/material';
import { top, updateData, getDate } from '../../assets/js/functions';
import preguntas from './preguntas';
import Logo from '../../assets/images/Spira-blanco.png';
import './styles.scss';

export default function Encuesta() {
	const [show, setShow] = useState(false);
	const [termino, setTermino] = useState(false);
	const [datos, setDatos] = useState({ pregunta29: '' });
	const [seccionVisible, setSeccionVisible] = useState(1);
	const params = useParams();
	const { id } = params;

	useEffect(() => {
		top();
		validar();
		console.log(id);
		setTimeout(() => {
			setShow(true);
		}, 1000);
	}, []);

	const validar = async () => {
		const contestado = localStorage.getItem('terminado2') || '0';
		setTermino(contestado === '1' ? true : false);
	};

	const cambiarSeccion = () => {
		top();
		setSeccionVisible(seccionVisible + 1);
	};

	const registrar = () => {
		const idBD = uniqid();
		if (Object.keys(datos).length < 28) {
			Swal.fire({
				title: '¡Espera!',
				text: 'Aun tienes preguntas sin responder',
				icon: 'warning',
				confirmButtonText: 'Cerrar',
				confirmButtonColor: '#E10613',
			});
			return null;
		}
		updateData(id, idBD, { ...datos, id: idBD, fechaDeRegistro: getDate() })
			.then(() => {
				Swal.fire({
					title: '¡Bien hecho!',
					text: 'Las respuestas se guardaron correctamente',
					icon: 'success',
					confirmButtonText: 'Cerrar',
					confirmButtonColor: '#E10613',
				}).then((result) => {
					if (result.isConfirmed) {
						setTermino(true);
						localStorage.setItem('terminado2', '1');
					}
				});
				return null;
			})
			.catch((e) =>
				Swal.fire({
					title: '¡Error!',
					text: 'Hubo un error al registrar las resuestas',
					icon: 'error',
					confirmButtonText: 'Cerrar',
					confirmButtonColor: '#E10613',
				})
			);
	};

	let backgroundStyles = '';
	backgroundStyles += `#root::after {background-color: #fff }`;

	return (
		<>
			<section id='encuesta'>
				<style>{backgroundStyles}</style>
				<div className='header-free'>
					<img src={Logo} alt='logo' />
				</div>
				{show && (
					<Container maxWidth='lg' style={{ marginBottom: '40px' }}>
						{termino === true && <h2 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>Gracias por contestar la encuesta</h2>}
						{termino === false && (
							<Grid container justifyContent='center' alignItems='center' spacing={4}>
								<Grid item xs={12} sm={12} md={12} lg={12}>
									<div className='titulo-encuesta'>Encuesta de clima organizacional</div>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12}>
									<>
										<label>Por favor, califique las siguientes preguntas, todas tus respuestas son anonimas. Considere 1 la calificación más baja, y 5 la más alta.</label>
									</>
								</Grid>
								{seccionVisible === 1 && (
									<>
										{Object.values(preguntas).map((val, id) => {
											if (val.seccion === 1) {
												return (
													<Grid item xs={12} sm={12} md={12} lg={12} key={id}>
														<div className='pregunta'>{val.pregunta}</div>
														<FormControl>
															<RadioGroup row onChange={(e) => setDatos({ ...datos, [val.labelBD]: e.target.value })} value={datos[val.labelBD]}>
																<FormControlLabel
																	value='1'
																	control={
																		<Radio
																			size='large'
																			sx={{
																				color: '#5a5a5a',
																				'&.Mui-checked': {
																					color: '#e10613',
																				},
																			}}
																		/>
																	}
																	label='1'
																	labelPlacement='bottom'
																/>
																<FormControlLabel
																	value='2'
																	control={
																		<Radio
																			size='large'
																			sx={{
																				color: '#5a5a5a',
																				'&.Mui-checked': {
																					color: '#e10613',
																				},
																			}}
																		/>
																	}
																	label='2'
																	labelPlacement='bottom'
																/>
																<FormControlLabel
																	value='3'
																	control={
																		<Radio
																			size='large'
																			sx={{
																				color: '#5a5a5a',
																				'&.Mui-checked': {
																					color: '#e10613',
																				},
																			}}
																		/>
																	}
																	label='3'
																	labelPlacement='bottom'
																/>
																<FormControlLabel
																	value='4'
																	control={
																		<Radio
																			size='large'
																			sx={{
																				color: '#5a5a5a',

																				'&.Mui-checked': {
																					color: '#e10613',
																				},
																			}}
																		/>
																	}
																	label='4'
																	labelPlacement='bottom'
																/>
																<FormControlLabel
																	value='5'
																	control={
																		<Radio
																			size='large'
																			sx={{
																				color: '#5a5a5a',
																				'&.Mui-checked': {
																					color: '#e10613',
																				},
																			}}
																		/>
																	}
																	label='5'
																	labelPlacement='bottom'
																/>
															</RadioGroup>
														</FormControl>
														<div className='escala'>
															<label>{val.escalaIzquierda}</label>
															<label>{val.escalaDerecha}</label>
														</div>
													</Grid>
												);
											}
										})}
									</>
								)}
								{/* seccionVisible 2 */}
								{seccionVisible === 2 && (
									<>
										{Object.values(preguntas).map((val, id) => {
											if (val.seccion === 2) {
												return (
													<Grid item xs={12} sm={12} md={12} lg={12} key={id}>
														<div className='pregunta'>{val.pregunta}</div>
														<FormControl>
															<RadioGroup row onChange={(e) => setDatos({ ...datos, [val.labelBD]: e.target.value })} value={datos[val.labelBD]}>
																<FormControlLabel
																	value='1'
																	control={
																		<Radio
																			size='large'
																			sx={{
																				color: '#5a5a5a',
																				'&.Mui-checked': {
																					color: '#e10613',
																				},
																			}}
																		/>
																	}
																	label='1'
																	labelPlacement='bottom'
																/>
																<FormControlLabel
																	value='2'
																	control={
																		<Radio
																			size='large'
																			sx={{
																				color: '#5a5a5a',
																				'&.Mui-checked': {
																					color: '#e10613',
																				},
																			}}
																		/>
																	}
																	label='2'
																	labelPlacement='bottom'
																/>
																<FormControlLabel
																	value='3'
																	control={
																		<Radio
																			size='large'
																			sx={{
																				color: '#5a5a5a',
																				'&.Mui-checked': {
																					color: '#e10613',
																				},
																			}}
																		/>
																	}
																	label='3'
																	labelPlacement='bottom'
																/>
																<FormControlLabel
																	value='4'
																	control={
																		<Radio
																			size='large'
																			sx={{
																				color: '#5a5a5a',

																				'&.Mui-checked': {
																					color: '#e10613',
																				},
																			}}
																		/>
																	}
																	label='4'
																	labelPlacement='bottom'
																/>
																<FormControlLabel
																	value='5'
																	control={
																		<Radio
																			size='large'
																			sx={{
																				color: '#5a5a5a',
																				'&.Mui-checked': {
																					color: '#e10613',
																				},
																			}}
																		/>
																	}
																	label='5'
																	labelPlacement='bottom'
																/>
															</RadioGroup>
														</FormControl>
														<div className='escala'>
															<label>{val.escalaIzquierda}</label>
															<label>{val.escalaDerecha}</label>
														</div>
													</Grid>
												);
											}
										})}
									</>
								)}
								{/* seccionVisible 3 */}
								{seccionVisible === 3 && (
									<>
										{Object.values(preguntas).map((val, id) => {
											if (val.seccion === 3) {
												return (
													<Grid item xs={12} sm={12} md={12} lg={12} key={id}>
														<div className='pregunta'>{val.pregunta}</div>
														<FormControl>
															<RadioGroup row onChange={(e) => setDatos({ ...datos, [val.labelBD]: e.target.value })} value={datos[val.labelBD]}>
																<FormControlLabel
																	value='1'
																	control={
																		<Radio
																			size='large'
																			sx={{
																				color: '#5a5a5a',
																				'&.Mui-checked': {
																					color: '#e10613',
																				},
																			}}
																		/>
																	}
																	label='1'
																	labelPlacement='bottom'
																/>
																<FormControlLabel
																	value='2'
																	control={
																		<Radio
																			size='large'
																			sx={{
																				color: '#5a5a5a',
																				'&.Mui-checked': {
																					color: '#e10613',
																				},
																			}}
																		/>
																	}
																	label='2'
																	labelPlacement='bottom'
																/>
																<FormControlLabel
																	value='3'
																	control={
																		<Radio
																			size='large'
																			sx={{
																				color: '#5a5a5a',
																				'&.Mui-checked': {
																					color: '#e10613',
																				},
																			}}
																		/>
																	}
																	label='3'
																	labelPlacement='bottom'
																/>
																<FormControlLabel
																	value='4'
																	control={
																		<Radio
																			size='large'
																			sx={{
																				color: '#5a5a5a',

																				'&.Mui-checked': {
																					color: '#e10613',
																				},
																			}}
																		/>
																	}
																	label='4'
																	labelPlacement='bottom'
																/>
																<FormControlLabel
																	value='5'
																	control={
																		<Radio
																			size='large'
																			sx={{
																				color: '#5a5a5a',
																				'&.Mui-checked': {
																					color: '#e10613',
																				},
																			}}
																		/>
																	}
																	label='5'
																	labelPlacement='bottom'
																/>
															</RadioGroup>
														</FormControl>
														<div className='escala'>
															<label>{val.escalaIzquierda}</label>
															<label>{val.escalaDerecha}</label>
														</div>
													</Grid>
												);
											}
										})}
									</>
								)}
								{/* seccionVisible 4 */}
								{seccionVisible === 4 && (
									<>
										{Object.values(preguntas).map((val, id) => {
											if (val.seccion === 4) {
												return (
													<Grid item xs={12} sm={12} md={12} lg={12} key={id}>
														<div className='pregunta'>{val.pregunta}</div>
														<FormControl>
															<RadioGroup row onChange={(e) => setDatos({ ...datos, [val.labelBD]: e.target.value })} value={datos[val.labelBD]}>
																<FormControlLabel
																	value='1'
																	control={
																		<Radio
																			size='large'
																			sx={{
																				color: '#5a5a5a',
																				'&.Mui-checked': {
																					color: '#e10613',
																				},
																			}}
																		/>
																	}
																	label='1'
																	labelPlacement='bottom'
																/>
																<FormControlLabel
																	value='2'
																	control={
																		<Radio
																			size='large'
																			sx={{
																				color: '#5a5a5a',
																				'&.Mui-checked': {
																					color: '#e10613',
																				},
																			}}
																		/>
																	}
																	label='2'
																	labelPlacement='bottom'
																/>
																<FormControlLabel
																	value='3'
																	control={
																		<Radio
																			size='large'
																			sx={{
																				color: '#5a5a5a',
																				'&.Mui-checked': {
																					color: '#e10613',
																				},
																			}}
																		/>
																	}
																	label='3'
																	labelPlacement='bottom'
																/>
																<FormControlLabel
																	value='4'
																	control={
																		<Radio
																			size='large'
																			sx={{
																				color: '#5a5a5a',

																				'&.Mui-checked': {
																					color: '#e10613',
																				},
																			}}
																		/>
																	}
																	label='4'
																	labelPlacement='bottom'
																/>
																<FormControlLabel
																	value='5'
																	control={
																		<Radio
																			size='large'
																			sx={{
																				color: '#5a5a5a',
																				'&.Mui-checked': {
																					color: '#e10613',
																				},
																			}}
																		/>
																	}
																	label='5'
																	labelPlacement='bottom'
																/>
															</RadioGroup>
														</FormControl>
														<div className='escala'>
															<label>{val.escalaIzquierda}</label>
															<label>{val.escalaDerecha}</label>
														</div>
													</Grid>
												);
											}
										})}
									</>
								)}
								{/* seccionVisible 5 */}
								{seccionVisible === 5 && (
									<>
										{Object.values(preguntas).map((val, id) => {
											if (val.seccion === 5) {
												return (
													<Grid item xs={12} sm={12} md={12} lg={12} key={id}>
														<div className='pregunta'>{val.pregunta}</div>
														<FormControl>
															<RadioGroup row onChange={(e) => setDatos({ ...datos, [val.labelBD]: e.target.value })} value={datos[val.labelBD]}>
																<FormControlLabel
																	value='1'
																	control={
																		<Radio
																			size='large'
																			sx={{
																				color: '#5a5a5a',
																				'&.Mui-checked': {
																					color: '#e10613',
																				},
																			}}
																		/>
																	}
																	label='1'
																	labelPlacement='bottom'
																/>
																<FormControlLabel
																	value='2'
																	control={
																		<Radio
																			size='large'
																			sx={{
																				color: '#5a5a5a',
																				'&.Mui-checked': {
																					color: '#e10613',
																				},
																			}}
																		/>
																	}
																	label='2'
																	labelPlacement='bottom'
																/>
																<FormControlLabel
																	value='3'
																	control={
																		<Radio
																			size='large'
																			sx={{
																				color: '#5a5a5a',
																				'&.Mui-checked': {
																					color: '#e10613',
																				},
																			}}
																		/>
																	}
																	label='3'
																	labelPlacement='bottom'
																/>
																<FormControlLabel
																	value='4'
																	control={
																		<Radio
																			size='large'
																			sx={{
																				color: '#5a5a5a',

																				'&.Mui-checked': {
																					color: '#e10613',
																				},
																			}}
																		/>
																	}
																	label='4'
																	labelPlacement='bottom'
																/>
																<FormControlLabel
																	value='5'
																	control={
																		<Radio
																			size='large'
																			sx={{
																				color: '#5a5a5a',
																				'&.Mui-checked': {
																					color: '#e10613',
																				},
																			}}
																		/>
																	}
																	label='5'
																	labelPlacement='bottom'
																/>
															</RadioGroup>
														</FormControl>
														<div className='escala'>
															<label>{val.escalaIzquierda}</label>
															<label>{val.escalaDerecha}</label>
														</div>
													</Grid>
												);
											}
										})}
									</>
								)}
								{/* seccionVisible 6 */}
								{seccionVisible === 6 && (
									<>
										{Object.values(preguntas).map((val, id) => {
											if (val.seccion === 6) {
												return (
													<Grid item xs={12} sm={12} md={12} lg={12} key={id}>
														<div className='pregunta'>{val.pregunta}</div>
														<FormControl>
															<RadioGroup row onChange={(e) => setDatos({ ...datos, [val.labelBD]: e.target.value })} value={datos[val.labelBD]}>
																<FormControlLabel
																	value='1'
																	control={
																		<Radio
																			size='large'
																			sx={{
																				color: '#5a5a5a',
																				'&.Mui-checked': {
																					color: '#e10613',
																				},
																			}}
																		/>
																	}
																	label='1'
																	labelPlacement='bottom'
																/>
																<FormControlLabel
																	value='2'
																	control={
																		<Radio
																			size='large'
																			sx={{
																				color: '#5a5a5a',
																				'&.Mui-checked': {
																					color: '#e10613',
																				},
																			}}
																		/>
																	}
																	label='2'
																	labelPlacement='bottom'
																/>
																<FormControlLabel
																	value='3'
																	control={
																		<Radio
																			size='large'
																			sx={{
																				color: '#5a5a5a',
																				'&.Mui-checked': {
																					color: '#e10613',
																				},
																			}}
																		/>
																	}
																	label='3'
																	labelPlacement='bottom'
																/>
																<FormControlLabel
																	value='4'
																	control={
																		<Radio
																			size='large'
																			sx={{
																				color: '#5a5a5a',

																				'&.Mui-checked': {
																					color: '#e10613',
																				},
																			}}
																		/>
																	}
																	label='4'
																	labelPlacement='bottom'
																/>
																<FormControlLabel
																	value='5'
																	control={
																		<Radio
																			size='large'
																			sx={{
																				color: '#5a5a5a',
																				'&.Mui-checked': {
																					color: '#e10613',
																				},
																			}}
																		/>
																	}
																	label='5'
																	labelPlacement='bottom'
																/>
															</RadioGroup>
														</FormControl>
														<div className='escala'>
															<label>{val.escalaIzquierda}</label>
															<label>{val.escalaDerecha}</label>
														</div>
													</Grid>
												);
											}
										})}
										<Grid item xs={12} sm={12} md={12} lg={12}>
											<div className='pregunta'>27. De 0 a 10, recomendarias a un amigo y/o familiar trabajar en la empresa</div>
											<FormControl>
												<RadioGroup
													row
													aria-labelledby='demo-row-radio-buttons-group-label'
													name='row-radio-buttons-group'
													onChange={(e) =>
														setDatos({
															...datos,
															pregunta28: e.target.value,
														})
													}
													value={datos.pregunta28}
												>
													<FormControlLabel
														value='1'
														control={
															<Radio
																size='large'
																sx={{
																	color: '#5a5a5a',
																	'&.Mui-checked': {
																		color: '#e10613',
																	},
																}}
															/>
														}
														label='1'
														labelPlacement='bottom'
													/>
													<FormControlLabel
														value='2'
														control={
															<Radio
																size='large'
																sx={{
																	color: '#5a5a5a',
																	'&.Mui-checked': {
																		color: '#e10613',
																	},
																}}
															/>
														}
														label='2'
														labelPlacement='bottom'
													/>
													<FormControlLabel
														value='3'
														control={
															<Radio
																size='large'
																sx={{
																	color: '#5a5a5a',
																	'&.Mui-checked': {
																		color: '#e10613',
																	},
																}}
															/>
														}
														label='3'
														labelPlacement='bottom'
													/>
													<FormControlLabel
														value='4'
														control={
															<Radio
																size='large'
																sx={{
																	color: '#5a5a5a',

																	'&.Mui-checked': {
																		color: '#e10613',
																	},
																}}
															/>
														}
														label='4'
														labelPlacement='bottom'
													/>
													<FormControlLabel
														value='5'
														control={
															<Radio
																size='large'
																sx={{
																	color: '#5a5a5a',
																	'&.Mui-checked': {
																		color: '#e10613',
																	},
																}}
															/>
														}
														label='5'
														labelPlacement='bottom'
													/>
													<FormControlLabel
														value='6'
														control={
															<Radio
																size='large'
																sx={{
																	color: '#5a5a5a',
																	'&.Mui-checked': {
																		color: '#e10613',
																	},
																}}
															/>
														}
														label='6'
														labelPlacement='bottom'
													/>
													<FormControlLabel
														value='7'
														control={
															<Radio
																size='large'
																sx={{
																	color: '#5a5a5a',
																	'&.Mui-checked': {
																		color: '#e10613',
																	},
																}}
															/>
														}
														label='7'
														labelPlacement='bottom'
													/>
													<FormControlLabel
														value='8'
														control={
															<Radio
																size='large'
																sx={{
																	color: '#5a5a5a',
																	'&.Mui-checked': {
																		color: '#e10613',
																	},
																}}
															/>
														}
														label='8'
														labelPlacement='bottom'
													/>
													<FormControlLabel
														value='9'
														control={
															<Radio
																size='large'
																sx={{
																	color: '#5a5a5a',
																	'&.Mui-checked': {
																		color: '#e10613',
																	},
																}}
															/>
														}
														label='9'
														labelPlacement='bottom'
													/>
													<FormControlLabel
														value='10'
														control={
															<Radio
																size='large'
																sx={{
																	color: '#5a5a5a',
																	'&.Mui-checked': {
																		color: '#e10613',
																	},
																}}
															/>
														}
														label='10'
														labelPlacement='bottom'
													/>
												</RadioGroup>
											</FormControl>
										</Grid>
										<Grid item xs={12} sm={12} md={12} lg={12}>
											<div className='pregunta'>28.¿Existe algo especial o único en tu empresa que lo caracterice como un gran lugar para trabajar? Da Ejemplos específicos.</div>
											<TextField fullWidth variant='outlined' value={datos.pregunta29} multiline rows={6} onChange={(e) => setDatos({ ...datos, pregunta29: e.target.value })} />
										</Grid>
									</>
								)}

								{/* Botones */}
								<Grid item xs={12} sm={12} md={12} lg={12}>
									{seccionVisible > 1 && (
										<Button className='blanco' variant='contained' style={{ background: '#e10613' }} onClick={() => setSeccionVisible(seccionVisible - 1)}>
											Regresar
										</Button>
									)}
									&nbsp;
									<Button className='blanco' variant='contained' style={{ background: '#e10613' }} onClick={() => (seccionVisible < 6 ? cambiarSeccion() : registrar())}>
										{seccionVisible < 6 ? 'Siguiente' : 'Enviar'}
									</Button>
								</Grid>
							</Grid>
						)}
					</Container>
				)}
			</section>
		</>
	);
}
