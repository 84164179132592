import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { TextField, MenuItem, Button, Paper, Switch, FormControlLabel } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import Swal from "sweetalert2";
import TopSection from "../../../../components/TopSection";
import Card from "../../../../components/Card";
import { searchDataByValue, updateData } from "../../../../assets/js/functions";
import "./styles.scss";

export default function ActualizarUsurios() {
	const params = useParams();
	const [campos, setCampos] = useState({});

	useEffect(() => {
		getInfo();
	}, []);

	const getInfo = async () => {
		const data = await searchDataByValue(process.env.REACT_APP_BD_USUARIOS, "id", params.id);
		setCampos(data);
	};

	const validar = async (e) => {
		e.preventDefault();
		updateData(process.env.REACT_APP_BD_USUARIOS, campos.id, campos)
			.then(() => {
				Swal.fire({
					title: "¡Bien hecho!",
					text: `El usuario se actualizó exitosamente`,
					icon: "success",
					confirmButtonText: "Cerrar",
					confirmButtonColor: "#c1212a",
				}).then((result) => {
					if (result.isConfirmed) {
						window.location.reload();
					}
				});
			})
			.catch((e) => console.log(e));
	};

	return (
		<>
			<div className='content-area'>
				<TopSection title='Actualizar usuarios' urlPadre={[["Usuarios", "/admin/usuarios"]]} />
				<section id='usuariosActualizar'>
					<Card name='Actualizar usuario'>
						<form onSubmit={validar}>
							<Grid container direction='row' spacing={2}>
								<Grid item lg={6} md={6} sm={6} xs={12}>
									<TextField InputLabelProps={{ shrink: true }} required fullWidth id='nombre' value={campos.nombre} label='Nombre' variant='outlined' onChange={(e) => setCampos({ ...campos, nombre: e.target.value })} />
								</Grid>
								<Grid item lg={6} md={6} sm={6} xs={12}>
									<TextField InputLabelProps={{ shrink: true }} type='email' required fullWidth id='correo-electronico' value={campos.correo} label='Correo Electrónico' variant='outlined' onChange={(e) => setCampos({ ...campos, correo: e.target.value })} />
								</Grid>
								<Grid item lg={2} md={2} sm={2} xs={12}>
									<div className='full center'>
										<Button type='submit' variant='info' className='btn'>
											Actualizar
										</Button>
									</div>
								</Grid>
							</Grid>
						</form>
					</Card>
				</section>
			</div>
		</>
	);
}
