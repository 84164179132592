import { getDate, updateData } from "../../assets/js/functions";

export default function ValidateUser({ children }) {
	const id = sessionStorage.getItem("3c393f98");

	let content = "";

	if (id !== null || id !== "") {
		content = <div>{children}</div>;
	} else {
		window.location.replace("/");
	}
	return content;
}
